
import Vue, { VueConstructor } from 'vue';
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
// @ts-ignore todo: convert placement.js to typescript
import { autocomplete, autocompleteForContract } from '@/modules/candidatePlacement/api/placement.js';
import { AxiosPromise } from 'axios';

export default (Vue as VueConstructor<Vue & {
  $refs: {
    autocomplete: InstanceType<typeof KPaginatedAutocomplete>
  }
}>).extend({
  name: 'PlacementAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    candidateId: {
      type: Number,
      default: 0,
    },
    contractTypeId: {
      type: Number,
      default: 0,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    autocompleteRequest(): AxiosPromise {
      if (this.contractTypeId > 0) {
        return autocompleteForContract(this.candidateId, this.contractTypeId, ...arguments);
      }
      return autocomplete(this.candidateId, ...arguments);
    },
    refresh(): void {
      this.$refs.autocomplete.refresh();
    },
  },
});
