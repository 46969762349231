<template>
  <v-row>
    <v-col
      v-if="values.grossHourlyWage.amount !== null"
      :class="detailClasses"
    >
      <span :class="labelClasses">{{ $t(`placement.recap.${isZzp? 'grossHourlyWageZZP' : 'grossHourlyWage'}`) }}</span>
      <span :class="valueClasses">{{ $n(values.grossHourlyWage.amount / 100, 'currency') }}</span>
      <span
        v-if="!isZzp"
        :class="captionClasses"
      >{{ $t('placement.recap.costPrice') }}: {{ $n(values.grossHourlyWage.temporaryWorkerCostPriceAmount / 100, 'currency') }}</span>
      <span
        :class="captionClasses"
      >{{ $t(`placement.recap.${values.grossHourlyWage.hasPremium? 'hasPremiums' : 'noPremiums'}`) }}</span>
    </v-col>
    <v-col
      v-if="values.customerRate.amount !== null"
      :class="detailClasses"
    >
      <span :class="labelClasses">{{ $t('placement.recap.customerRate') }}</span>
      <span :class="valueClasses">{{ $n(values.customerRate.amount / 100, 'currency') }}</span>
    </v-col>
    <v-col
      v-if="values.customerMargin.amount !== null"
      :class="detailClasses"
    >
      <span :class="labelClasses">{{ $t(`placement.recap.${isZzp? 'customerMarginZZP' : 'customerMargin'}`) }}</span>
      <span
        :class="[marginColor, valueClasses]"
      >{{ Math.round(values.customerMargin.percentage / 100) }}%</span>
      <span :class="captionClasses">{{ $n(values.customerMargin.amount / 100, 'currency') }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    values: {
      type: Object,
      required: true,
    },
    isZzp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailClasses: 'd-flex flex-column align-center',
      labelClasses: 'text-body-1 font-weight-medium',
      valueClasses: 'font-weight-bold text-h5 my-1',
      captionClasses: 'text-caption font-italic secondary--text',
    };
  },
  computed: {
    marginColor() {
      const percentage = this.values.customerMargin.percentage;

      if (percentage < 2000) {
        return 'red--text';
      } else if (percentage > 2500) {
        return 'green--text';
      }

      return 'orange--text';
    },
  },
};
</script>
