<template>
  <v-simple-table
    dense
    class="transparent sign-table"
  >
    <tbody v-if="!unAvailableText">
      <tr
        v-for="(signable, idx) in signables"
        :key="idx"
      >
        <td class="pa-1 status-col">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                :color="getIconColor(signable.signableStatusId)"
                small
                v-on="on"
              >
                {{ getStatusIcon(signable.signableStatusId) }}
              </v-icon>
            </template>
            <span>{{ getTooltipText(signable.signableStatusId, signable.signableStatusUpdatedAt) }}</span>
          </v-tooltip>
        </td>
        <td class="pa-1 text-col">
          {{ getPlacementDocumentTypeText(signable.type) }}
        </td>
        <td class="pa-1">
          <v-tooltip top>
            <template #activator="{ on }">
              <div
                style="width: min-content"
                v-on="on"
              >
                <v-btn
                  v-if="canInvite(signable.signableStatusId)"
                  :disabled="(options?.[signable.type] && !options?.[signable.type]?.email) || inviting"
                  small
                  color="text--secondary"
                  icon
                  @click="handleInviteClick(signable.signableStatusId, signable.type)"
                >
                  <v-icon>$send-email</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ getInviteTooltipText(signable) }}</span>
          </v-tooltip>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td class="pa-1 status-col">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                color="red"
                small
                v-on="on"
              >
                $error
              </v-icon>
            </template>
            <span>{{ unAvailableText }}</span>
          </v-tooltip>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { signableStatus } from '@/application/enums/signableStatus.js';
import { placementDocumentTypes } from '@/application/enums/placementDocumentTypes.js';
import eventBus from '@/application/eventBus.ts';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'SignableStatus',
  props: {
    signables: {
      type: Array,
    },
    contextId: {
      type: [Number, String],
      required: true,
    },
    signeeId: {
      type: [Number, String],
      required: true,
    },
    requestSigningRequest: {
      type: Function,
      required: true,
    },
    sendSigningReminderRequest: {
      type: Function,
      required: true,
    },
    unAvailableText: {
      type: String,
    },
    options: {
      type: Object,
    },
  },
  data() {
    return {
      inviting: false,
    };
  },
  methods: {
    getInviteTooltipText({ signableStatusId, type }) {
      const options = this.options?.[type];

      if (!options?.email && options?.disabledText) {
        return options?.disabledText;
      }

      if (signableStatusId === signableStatus.NEW) {
        return options?.email ? this.$t('contract.actions.inviteInvitee', { invitee: options?.email }) : this.$t('contract.actions.invite');
      }
      if (signableStatusId === signableStatus.INVITED) {
        return options?.email ? this.$t('contract.actions.reInviteInvitee', { invitee: options?.email }) : this.$t('contract.actions.reInvite');
      }
      return '';
    },
    getTooltipText(signableStatusId, signableStatusUpdatedAt) {
      const key = Object.keys(signableStatus).find(key => signableStatus[key] === signableStatusId);
      return this.$t(`contract.signableStatus.${key}`, { date: dayjs(signableStatusUpdatedAt).format('LLL') });
    },
    getPlacementDocumentTypeText(placementDocumentTypeId) {
      const key = Object.keys(placementDocumentTypes)
        .find(key => placementDocumentTypes[key] === placementDocumentTypeId);
      return this.$t(`contract.placementDocumentTypes.${key}`);
    },
    getIconColor(signableStatusId) {
      if (signableStatusId === signableStatus.INVITED || signableStatusId === signableStatus.REMINDED) {
        return 'blue';
      }
      if (signableStatusId === signableStatus.NEW) {
        return 'red';
      }
      if (signableStatusId === signableStatus.SIGNING) {
        return 'green';
      }
    },
    getStatusIcon(signableStatusId) {
      if (signableStatusId === signableStatus.INVITED || signableStatusId === signableStatus.REMINDED) {
        return '$waiting';
      }
      if (signableStatusId === signableStatus.SIGNING) {
        return '$complete';
      }
      if (signableStatusId === signableStatus.NEW) {
        return '$error';
      }
      return null;
    },
    canInvite(signableStatusId) {
      return signableStatusId === signableStatus.NEW || signableStatusId === signableStatus.INVITED;
    },
    handleInviteClick(signableStatusId, placementDocumentTypeId) {
      if (signableStatusId === signableStatus.NEW) {
        this.handleInvite(placementDocumentTypeId);
      }
      if (signableStatusId === signableStatus.INVITED) {
        this.handleRemind(placementDocumentTypeId);
      }
    },
    async handleInvite(placementDocumentTypeId) {
      this.inviting = true;
      await this.handleInviteCall(this.requestSigningRequest, placementDocumentTypeId);
      this.inviting = false;
    },
    async handleRemind(placementDocumentTypeId) {
      this.inviting = true;
      await this.handleInviteCall(this.sendSigningReminderRequest, placementDocumentTypeId);
      this.inviting = false;
    },
    async handleInviteCall(request, placementDocumentTypeId) {
      try {
        await request(this.signeeId, this.contextId, placementDocumentTypeId);
        this.$emit('change');
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('contract.messages.signSuccess'),
        });
      } catch (e) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table.sign-table {
  table tbody tr td {
    border: none;
  }

  table tbody tr {
    background-color: transparent;
  }

  td.status-col {
    width: 30px;
  }

  td.text-col {
    width: 75px;
  }
}
</style>
