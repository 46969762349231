import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (page, perPage, search, sortBy, descending, params) =>
  getPaginated('contract-template', page, perPage, search, sortBy, descending, params);

export const autocomplete = (page, perPage, search, sortBy, descending, params) =>
  getPaginated('contract-template/autocomplete', page, perPage, search, sortBy, descending, params);

export const show = (templateId) => get(`contract-template/${templateId}`);

export const create = (templateId) => post('contract-template', templateId);

export const update = (template) => put(`contract-template/${template.id}`, template);
