var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.KFieldGroup,{attrs:{"language-prefix":"placement.signing"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[(_setup.can('placement.signManuallyEmployer'))?_c(_setup.KCheckbox,{attrs:{"disabled":_vm.isUpdateForm && _vm.isManuallySignedEmployer,"dense":"","field":"isManuallySignedEmployer"},model:{value:(_vm.values.isManuallySignedEmployer),callback:function ($$v) {_vm.$set(_vm.values, "isManuallySignedEmployer", $$v)},expression:"values.isManuallySignedEmployer"}}):_vm._e(),(_vm.values.isManuallySignedEmployer)?_c(_setup.KFileField,{attrs:{"contain-preview-image":"","preview-width":85,"preview-height":120,"show-size":"","rules":[_setup.backgroundImageSizeRestriction],"accept":"image/png, image/jpeg, application/pdf","field":"employerSignedDocument","requirements-text":_vm.$t('placement.signing.requirementsText'),"requirements":{
          allowedFormat: '.jpg / .png / .pdf',
          imageSize: `${_vm.$t('global.smallerThan')} 20MB`,
        }},model:{value:(_vm.values.employerSignedDocument),callback:function ($$v) {_vm.$set(_vm.values, "employerSignedDocument", $$v)},expression:"values.employerSignedDocument"}}):_vm._e(),(_setup.can('placement.signManuallyCandidate'))?_c(_setup.KCheckbox,{attrs:{"disabled":_vm.isUpdateForm && _vm.isManuallySignedCandidate,"dense":"","field":"isManuallySignedCandidate"},model:{value:(_vm.values.isManuallySignedCandidate),callback:function ($$v) {_vm.$set(_vm.values, "isManuallySignedCandidate", $$v)},expression:"values.isManuallySignedCandidate"}}):_vm._e(),(_vm.values.isManuallySignedCandidate)?_c(_setup.KFileField,{attrs:{"contain-preview-image":"","preview-width":85,"preview-height":120,"show-size":"","rules":[_setup.backgroundImageSizeRestriction],"accept":"image/png, image/jpeg, application/pdf","field":"candidateSignedDocument","requirements-text":_vm.$t('placement.signing.requirementsText'),"requirements":{
          allowedFormat: '.jpg / .png / .pdf',
          imageSize: `${_vm.$t('global.smallerThan')} 20MB`,
        }},model:{value:(_vm.values.candidateSignedDocument),callback:function ($$v) {_vm.$set(_vm.values, "candidateSignedDocument", $$v)},expression:"values.candidateSignedDocument"}}):_vm._e(),(_vm.values.isManuallySignedEmployer || _vm.values.isManuallySignedCandidate)?_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("placement.signing.isManuallySignedWarning", { date: _setup.dayjs(_vm.values.startDate, "YYYY-MM-DD").format("LL") }))+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }