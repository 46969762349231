<template>
  <div id="placement-index">
    <KCrudLayout :with-search="false">
      <template #header>
        {{ $tc('placement.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          :disabled="candidateCanPlaceFailureReasons.length > 0"
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('placement.title', 1) }) }}
        </v-btn>
        <PlacementForm
          ref="createPlacementForm"
          v-model="createDialog"
          :candidate-id="candidate.id"
          :candidate-invoice-interval="candidateInvoiceInterval"
          :request="createRequest"
          :title="$tc('placement.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <v-row v-if="candidateCanPlaceFailureReasons.length > 0">
          <v-col cols="12">
            <actionable-alert
              :message="$t('actionables.messages.canPlaceActionbleFailed')"
              :failure-reasons="candidateCanPlaceFailureReasons"
            >
              <template #actions>
                <div v-if="candidateIsMissingContractOnly">
                  <v-btn
                    class="mx-1"
                    color="primary"
                    depressed
                    tile
                    @click="openUpdateContract"
                  >
                    {{ $t('actions.createResource', { resource: $tc('contract.title') }) }}
                  </v-btn>
                  <ContractForm
                    ref="createContractForm"
                    v-model="contractUpdateDialog"
                    :candidate-id="candidate.id"
                    :request="updateContractRequest"
                    :title="$tc('contract.title',1)"
                    :values="contractToCreate"
                    @change="checkCanBePlacedActionable"
                  />
                </div>
                <div v-else>
                  <v-btn
                    class="mx-1"
                    color="primary"
                    depressed
                    tile
                    @click="openUpdateCandidate"
                  >
                    {{ $t('actions.updateResource', { resource: $tc('candidate.title') }) }}
                  </v-btn>
                  <CandidateForm
                    v-model="candidateUpdateDialog"
                    :request="updateCandidateRequest"
                    :title="$tc('candidate.title')"
                    :values="candidateToUpdate"
                    is-update-form
                    @change="checkCanBePlacedActionable"
                  />
                </div>
              </template>
            </actionable-alert>
          </v-col>
        </v-row>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          language-prefix="placement.fields"
          resource="placement"
          :can-delete="canDelete"
          @click:edit="openUpdate"
          @click:copy="handleOpenCopyDialog"
          @click:delete="deletePlacement"
        >
          <template #item.isActive="{ item }">
            <v-icon
              v-if="item.isActive"
              color="success"
            >
              $circle
            </v-icon>
          </template>
          <template #item.startDate="{ item }">
            {{ dayjs(item.startDate, 'YYYY-MM-DD').format('LL') }}
          </template>
          <template #item.endDate="{ item }">
            <span v-if="item.endDate">
              {{ dayjs(item.endDate, 'YYYY-MM-DD').format('LL') }}
            </span>
            <span v-else>{{ $t('placement.indefinitely') }}</span>
          </template>
          <template #item.grossHourlyWage="{ item }">
            {{ typeof item.grossHourlyWage === 'number' ? $n(parseInt(item.grossHourlyWage) / 100, 'currency') : '-' }}
          </template>
          <template #item.hoursWorked="{ item }">
            <span>
              {{ item.hoursWorked }}
            </span>
          </template>
          <template #item.signableStatusId="{ item }">
            <SignableStatus
              :signables="item.signables"
              :context-id="item.id"
              :signee-id="candidate.id"
              :request-signing-request="requestSigningRequest"
              :send-signing-reminder-request="sendSigningReminderRequest"
              :options="{
                [placementDocumentTypes.EMPLOYER]: {
                  email: item.employerEmail,
                  disabledText: $t('placement.messages.noContactPerson'),
                },
                [placementDocumentTypes.CANDIDATE]: {
                  email: item.candidateEmail,
                  disabledText: $t('placement.messages.noCandidateEmail'),
                }
              }"
              @change="$refs.table.reload()"
            />
          </template>
          <template #actions="{ item }">
            <v-menu
              bottom
              left
              :offset-y="true"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>$filePdf</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(signable, i) in item.signables"
                  :key="i"
                  @click="downloadPlacement(item, signable.type)"
                >
                  <v-list-item-title>{{ getDownloadText(signable.type) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <PlacementForm
      ref="updatePlacementForm"
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('placement.title',1)"
      :values="updateFormValues"
      is-update-form
      :candidate-id="candidate.id"
      :candidate-invoice-interval="candidateInvoiceInterval"
      :is-manually-signed-employer="isManuallySignedEmployer"
      :is-manually-signed-candidate="isManuallySignedCandidate"
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Placement from '@/application/models/Placement.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import {
  create,
  show,
  update,
  remove,
  requestSigning,
  sendSigningReminder,
  fetchPdf,
} from '@/modules/candidatePlacement/api/placement.js';
import { index } from '@/modules/candidate/api/placement.js';
import PlacementForm from '@/modules/candidatePlacement/components/PlacementForm.vue';
import dayjs from '@/plugins/dayjs';
import SignableStatus from '@/modules/candidate/components/SignableStatus.vue';
import downloadFile from '@/application/util/downloadFile.js';
import { placementDocumentTypes } from '@/application/enums/placementDocumentTypes.js';
import ActionableAlert from '@/modules/actionable/views/ActionableAlert.vue';
import Contract from '@/application/models/Contract.js';
import Candidate from '@/application/models/Candidate.js';
import { create as createContract } from '@/modules/contract/api';
import { show as showCandidate, update as updateCandidate } from '@/modules/candidate/api/candidate.js';
import { actionableCanPlaceCandidate } from '@/modules/actionable/api';
import CandidateForm from '@/modules/candidate/components/CandidateForm.vue';
import ContractForm from '@/modules/contract/components/ContractForm.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Placements',
  components: {
    CandidateForm,
    ContractForm,
    ActionableAlert,
    SignableStatus,
    PlacementForm,
    KCrudTable,
    KCrudLayout,
  },
  props: {
    candidate: {
      type: Object,
    },
  },
  data() {
    return {
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Placement(),
      createFormValues: new Placement(),
      candidateCanPlaceFailureReasons: [],
      candidateUpdateDialog: false,
      contractUpdateDialog: false,
      candidateToUpdate: new Candidate(),
      contractToCreate: new Contract(),
      isManuallySignedEmployer: false,
      isManuallySignedCandidate: false,
    };
  },
  computed: {
    ...mapGetters({
      can: 'authorisation/can',
    }),
    placementDocumentTypes: () => placementDocumentTypes,
    candidateIsMissingContractOnly() {
      return this.candidateCanPlaceFailureReasons.length === 1 && this.candidateCanPlaceFailureReasons[0] === 'hasNoContract';
    },
    candidateInvoiceInterval() {
      return this.candidate.isZzp ? this.candidate.selfEmployedSetting?.invoiceBillingIntervalId : null;
    },
    crudHeaders() {
      return [{
        text: '',
        value: 'isActive',
        align: 'center',
        sortable: false,
        filterable: false,
        groupable: false,
        width: '1%',
        cellClass: 'no-table-separator',
      },
      {
        value: 'employerName',
        language: 'placement.crudHeaders.employerName',
        cellClass: 'no-table-separator',
      },
      {
        value: 'positionName',
        language: 'placement.crudHeaders.positionName',
      },
      {
        value: 'grossHourlyWage',
        language: 'placement.crudHeaders.rate',
        sortable: false,
      },
      {
        value: 'startDate',
        language: 'placement.crudHeaders.startDate',
      },
      {
        value: 'endDate',
        language: 'placement.crudHeaders.endDate',
      },
      {
        value: 'hoursWorked',
        language: 'placement.crudHeaders.hoursWorked',
        align: 'end',
      },
      {
        value: 'signableStatusId',
        language: 'contract.crudHeaders.signableStatusId',
      }];
    },
  },
  watch: {
    '$route.params.candidateId': {
      immediate: true,
      handler: async function () {
        await this.checkCanBePlacedActionable();
      },
    },
    candidate: {
      immediate: true,
      async handler(value) {
        eventBus.$emit('setBreadcrumbs', [
          {
            exact: true,
            to: { name: 'candidate.index' },
            text: this.$tc('candidate.title', 2),
          },
          {
            exact: true,
            to: { name: 'candidate.show' },
            text: value.name,
          }],
        );
        await this.$refs.table?.reload();
      },
    },
  },
  methods: {
    dayjs,
    indexRequest() {
      return index(this.candidate.id, ...arguments);
    },
    createRequest() {
      return create(this.candidate.id, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    requestSigningRequest(candidateId, placementId, placementDocumentTypeId) {
      return requestSigning(candidateId, placementId, placementDocumentTypeId);
    },
    sendSigningReminderRequest(candidateId, placementId, placementDocumentTypeId) {
      return sendSigningReminder(candidateId, placementId, placementDocumentTypeId);
    },
    canDelete(item) {
      return item.isDeletable;
    },
    async deletePlacement(placement) {
      try {
        eventBus.$emit('confirm', {
          title: this.$t('actions.deleteConfirmation.title'),
          body: this.$t('actions.deleteConfirmation.bodyAlt', {
            resource: this.$tc('placement.title', 1).toLowerCase(),
          }),
          confirmCallback: async () => {
            await remove(placement.id);
            this.$refs.table.reload();
            eventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('actions.deleteResource', { resource: this.$tc('placement.title', 1) }),
            });
          },
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
        throw error;
      }
    },
    handleOpenCreateDialog() {
      this.$refs.createPlacementForm.reset();
      this.createFormValues = new Placement();
      this.createDialog = true;
    },
    async openUpdate(item) {
      this.$refs.updatePlacementForm.reset();
      this.updateFormValues = new Placement();
      const { data: { data } } = await show(item.id);
      this.updateFormValues.mapResponse(data);
      // todo: fix properly
      // fix to make sure that vat is filled when it is zero s4u-3201
      this.updateFormValues.vatChargingPercentage = data.vatChargingPercentage;
      this.isManuallySignedEmployer = this.updateFormValues.isManuallySignedEmployer;
      this.isManuallySignedCandidate = this.updateFormValues.isManuallySignedCandidate;
      this.updateDialog = true;
    },
    async handleOpenCopyDialog(item) {
      this.$refs.createPlacementForm.reset();
      this.createFormValues = new Placement();
      const { data: { data } } = await show(item.id);
      this.createFormValues.mapResponse(data);

      // todo: fix properly
      // fix to make sure that vat is filled when it is zero s4u-3201
      this.createFormValues.vatChargingPercentage = data.vatChargingPercentage;

      // Because it is a copy, it should not have a primary-key
      this.createFormValues.id = null;
      this.createDialog = true;
    },
    generatePlacementDownloadFileName(item, placementDocumentTypeId) {
      const formattedStartDate = dayjs(item.startDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
      const formattedEndDate = item.endDate ? dayjs(item.endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : this.$t('placement.fileDownload.indefinitely');
      
      if (placementDocumentTypeId === placementDocumentTypes.CANDIDATE) {
        return `${this.$t('placement.fileDownload.placementConfirmation')} ${this.candidate.name} - ${item.employerName} ${formattedStartDate} - ${formattedEndDate} (${item.positionName})`;
      }
      
      if (placementDocumentTypeId === placementDocumentTypes.EMPLOYER) {
        return `${this.$t('placement.fileDownload.placementConfirmation')} ${item.employerName} - ${this.candidate.name} ${formattedStartDate} - ${formattedEndDate} (${item.positionName})`;
      }

      return this.$t('placement.fileDownload.placementConfirmation');
    },
    async downloadPlacement(item, placementDocumentTypeId) {
      const fileName = this.generatePlacementDownloadFileName(item, placementDocumentTypeId);

      try {
        await downloadFile(fetchPdf(this.candidate.id, item.id, placementDocumentTypeId), fileName);
      } catch (error) {
        console.warn(error);
      }
    },
    getDownloadText(placementDocumentTypeId) {
      const key = Object.keys(placementDocumentTypes)
        .find(key => placementDocumentTypes[key] === placementDocumentTypeId);
      const typeText = this.$t(`contract.placementDocumentTypes.${key}`);
      return this.$t('contract.actions.download', { placementDocumentType: typeText.toLowerCase() });
    },
    async checkCanBePlacedActionable() {
      // check if candidate data is sufficient for placement
      const response = await actionableCanPlaceCandidate(this.candidate.id);
      this.candidateCanPlaceFailureReasons = response.data.reasons;
    },
    async openUpdateCandidate() {
      this.candidateToUpdate = new Candidate();
      const response = await showCandidate(this.candidate.id);
      this.candidateToUpdate.mapResponse(response.data.data);
      this.candidateUpdateDialog = true;
    },
    updateCandidateRequest() {
      return updateCandidate(this.candidateToUpdate);
    },
    async openUpdateContract() {
      this.contractToCreate = new Contract();
      this.contractUpdateDialog = true;
    },
    updateContractRequest() {
      return createContract(this.candidate.id, this.contractToCreate);
    },
  },
};
</script>
