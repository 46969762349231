<template>
  <k-form-dialog
    ref="dialogForm"
    v-bind="$attrs"
    :panels="panels"
    v-on="$listeners"
  >
    <template #panel.0>
      <k-field-group language-prefix="contract.fields">
        <v-row no-gutters>
          <ContractTemplateAutocomplete
            v-model="values.contractTemplate"
            :candidate-is-zzp="candidateIsZzp"
            :clearable="false"
            field="template"
            required
            class="pr-2"
            :disabled="isUpdateForm"
          />
        </v-row>
        <v-row v-if="contractFailureReasons.length > 0">
          <v-col cols="12">
            <actionable-alert
              :message="$t('actionables.messages.contractActionableFailed')"
              :failure-reasons="contractFailureReasons"
            >
              <template #actions>
                <v-btn
                  class="mx-1"
                  color="primary"
                  depressed
                  tile
                  @click="openUpdateCandidate"
                >
                  {{ $t('actions.updateResource', { resource: $tc('candidate.title') }) }}
                </v-btn>
                <CandidateForm
                  v-model="candidateUpdateDialog"
                  :request="updateCandidateRequest"
                  :title="$tc('candidate.title')"
                  :values="candidateToUpdate"
                  is-update-form
                  @change="checkContractActionable"
                />
              </template>
            </actionable-alert>
          </v-col>
        </v-row>
        <template v-if="!!values.contractTemplate && contractFailureReasons.length === 0">
          <v-row no-gutters>
            <TradeNameAutocomplete
              v-model="values.tradeName"
              grid="col-12 pr-2"
              field="tradeName"
              :trade-names="tradeNames"
              required
              :disabled="isUpdateForm"
            />
            <PlacementAutocomplete
              ref="placementAutocomplete"
              v-model="values.initialPlacement"
              :candidate-id="candidateId"
              :contract-type-id="values.contractTemplate.contractTypeId"
              :return-object="true"
              :required="values.isManuallySigned"
              grid="col-12 pr-2"
              field="initialPlacement"
              :disabled="isUpdateForm && values.hadInitialPlacementOnOpen && values.wasManuallySignedOnOpen"
            />
            <KDateField
              ref="startDateInput"
              v-model="values.startDate"
              field="dateStart"
              grid="col-12 pr-2"
              required
              :disabled="isUpdateForm"
            />
            <KDateField
              v-model="values.endDate"
              :min-date="values.startDate"
              field="dateEnd"
              grid="col-12 pr-2"
              :required="values.contractTemplate.isFixedTerm"
              :disabled="isUpdateForm"
            />
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-alert
                v-if="willTerminateCurrentContract"
                type="info"
                transition="scale-transition">
                <div>
                  {{ $t('actionables.messages.newContractEndsCurrent') }}
                </div>
                <ul>
                  <li v-if="newContractStartDate">
                    {{
                      $t('actionables.messages.startDateOfNewContract', { date: formatDate(newContractStartDate) })
                    }}
                  </li>
                  <li>
                    {{
                      $t('actionables.messages.endDateOfCurrentContract', { date: currentContractEndDate ? formatDate(currentContractEndDate) : $t('actionables.messages.indefiniteContract') })
                    }}
                  </li>
                </ul>
              </v-alert>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <KRadioGroup
              v-if="values.contractTemplate.contractTypeId === contractType.TEMP_BASE && values.tradeName && values.startDate && values.endDate && probationOptions.length > 0"
              v-model="values.probationPeriodId"
              :items="probationOptions"
              column
              field="probationDays"
              grid="col-12 pr-2"
              required
              :disabled="isUpdateForm"
            />
          </v-row>
          <v-row
            v-if="values.contractTemplate.contractTypeId === contractType.SELF_EMPLOYED"
            no-gutters
          >
            <KRadioGroup
              v-model="values.vatChargingOptionId"
              :items="vatChargingOptionIds"
              column
              field="vatChargingOption"
              grid="col-12 pr-2"
              required
              :disabled="isUpdateForm"
            />
            <KRadioGroup
              v-model="values.hasDefaultMediation"
              :items="mediationOptions"
              column
              field="hasMediation"
              grid="col-12 pr-2"
              required
              :disabled="isUpdateForm"
            />
            <KPercentageField
              v-if="values.hasDefaultMediation === false"
              v-model="values.mediation"
              column
              field="mediationPercentage"
              grid="col-4 pr-2 mt-2"
              required
              :disabled="isUpdateForm"
            />
            <KRadioGroup
              v-model="values.contractPrefundingTypeId"
              :items="prefundingOptions"
              column
              field="hasPrefunding"
              grid="col-12 pr-2"
              required
              :disabled="isUpdateForm"
            />
          </v-row>
          <v-row
            v-if="values.contractTemplate.contractTypeId === contractType.TEMP_BASE"
            class="align-center"
            no-gutters
          >
            <KFigureField
              v-model="values.hours"
              :suffix="$t('contract.fields.hasHours.hour')"
              column
              field="hasHours.hours"
              grid="col-sm-4 pr-2"
              min="0"
              required
              :disabled="isUpdateForm"
            />
            <KSelect
              v-model="values.placementHourPeriodId"
              :items="timeUnitOptions"
              :label="null"
              :prefix="$t('contract.fields.hasHours.per')"
              grid="col-sm-4"
              item-text="text"
              item-value="value"
              :disabled="isUpdateForm"
            />
          </v-row>
          <template v-if="canSignManually">
            <KCheckbox
              v-model="values.isManuallySigned"
              field="isManuallySigned"
              :disabled="isUpdateForm && values.wasManuallySignedOnOpen"
            />

            <KFileField
              v-if="values.isManuallySigned"
              v-model="values.candidateSignedDocument"
              contain-preview-image
              :preview-width="85"
              :preview-height="120"
              show-size
              :rules="[backgroundImageSizeRestriction]"
              accept="image/png, image/jpeg, application/pdf"
              field="candidateSignedDocument"
              :requirements-text="$t('contract.messages.requirementsText')"
              :requirements="{
                allowedFormat: '.jpg / .png / .pdf',
                imageSize: `${$t('global.smallerThan')} 20MB`,
              }"
            />
            <div
              v-if="values.isManuallySigned"
              class="red--text mt-4"
            >
              {{ $t('contract.messages.isManuallySignedWarning') }}
            </div>
          </template>
        </template>
      </k-field-group>
    </template>
  </k-form-dialog>
</template>

<script>
import { contract, template } from '@/application/enums/contract';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KFigureField from '@/components/crud/fields/KFigureField.vue';
import KPercentageField from '@/components/crud/fields/KPercentageField.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import ContractTemplateAutocomplete from '@/modules/contract/components/ContractTemplateAutocomplete.vue';
import { getTranslatedEnumOptions } from '@/application/helpers/enum.js';
import TradeNameAutocomplete from '@/modules/companyEntity/components/TradeNameAutocomplete.vue';
import { contractTradeNames } from '@/modules/companyEntity/api/tradeNameAutocomplete.ts';
import ActionableAlert from '@/modules/actionable/views/ActionableAlert.vue';
import { show as showCandidate, update as updateCandidate } from '@/modules/candidate/api/candidate.js';
import Candidate from '@/application/models/Candidate.js';
import CandidateForm from '@/modules/candidate/components/CandidateForm.vue';
import { actionableCanUseContractTemplate, actionableContractPossibleForPeriod } from '@/modules/actionable/api';
import PlacementAutocomplete from '@/modules/candidatePlacement/components/CandidatePlacementAutocomplete.vue';
import { getProbationOptions } from '@/modules/contract/api';
import dayjs from '@/plugins/dayjs';
import { mapGetters } from 'vuex';
import KFileField from '@/components/crud/fields/KFileField.vue';

export default {
  name: 'ContractForm',
  components: {
    PlacementAutocomplete,
    CandidateForm,
    ActionableAlert,
    TradeNameAutocomplete,
    ContractTemplateAutocomplete,
    KSelect,
    KPercentageField,
    KRadioGroup,
    KFigureField,
    KDateField,
    KFieldGroup,
    KFormDialog,
    KCheckbox,
    KFileField,
  },
  props: {
    candidateId: {
      type: Number,
    },
    values: {
      type: Object,
    },
    isUpdateForm: {
      default: false,
      type: Boolean,
    },
  },
  data: () => {
    return {
      mediationSelected: contract.mediation.DEFAULT,
      contractType: template.contractType,
      tradeNames: [],
      contractFailureReasons: [],
      candidateUpdateDialog: false,
      candidateToUpdate: new Candidate(),
      availableProbationPeriods: [],
      newContractStartDate: null,
      currentContractEndDate: null,
      willTerminateCurrentContract: false,
      candidateIsZzp: null,
    };
  },
  computed: {
    ...mapGetters({ can: 'authorisation/can' }),
    canSignManually() {
      return this.can('contract.signManually');
    },
    panels() {
      return [
        { name: this.$t('contract.tabs.default') },
      ];
    },
    vatChargingOptionIds() {
      return getTranslatedEnumOptions(contract.vatChargingOptionId, 'contract.fields.vatChargingOptions');
    },
    mediationOptions() {
      return getTranslatedEnumOptions(contract.mediation, 'contract.fields.mediation');
    },
    prefundingOptions() {
      return getTranslatedEnumOptions(contract.prefunding, 'contract.fields.prefunding');
    },
    timeUnitOptions() {
      return getTranslatedEnumOptions(contract.timeUnits, 'contract.fields.timeUnits');
    },
    probationOptions() {
      const allOptions = getTranslatedEnumOptions(contract.probationPeriods, 'contract.fields.probationPeriods');
      const availableOptions = [];
      this.availableProbationPeriods.forEach(availablePeriod => {
        availableOptions.push(allOptions[availablePeriod]);
      });
      return availableOptions;
    },
    probationPeriodsDependencies() {
      return this.values.tradeName && this.values.startDate && this.values.endDate;
    },
  },
  watch: {
    'mediationSelected'(value) {
      if (value === 0) this.values.mediation = 5;
    },
    async 'values.contractTemplate'(value) {
      await this.checkContractActionable();
      this.$refs.placementAutocomplete?.refresh();
    },
    'probationPeriodsDependencies'(value) {
      if (this.values.tradeName !== null && this.values.startDate !== null && this.values.endDate !== null) {
        this.setProbationOptions();
        return;
      }
      this.availableProbationPeriods = [];
    },
    'values.startDate'(value) {
      this.checkContractDatesActionable();
    },
    'values.endDate'(value) {
      this.checkContractDatesActionable();
    },
  },
  async mounted() {
    const res = await showCandidate(this.candidateId);
    this.candidateIsZzp = res.data.data.isZzp;
  },
  methods: {
    async setProbationOptions() {
      const response = await getProbationOptions(this.candidateId, this.values.tradeName.id, this.values.startDate, this.values.endDate, this.values.id ? this.values.id : null);
      const options = response.data.data;

      if (!this.isUpdateForm) {
        this.values.probationPeriodId = options.indexOf(1) !== -1 ? 1 : 0;
      }

      this.availableProbationPeriods = options;
    },
    async setTradeNames(contractTemplateId) {
      if (contractTemplateId) {
        const response = await contractTradeNames(contractTemplateId);
        this.tradeNames = response.data.data;
      }
    },
    async checkContractActionable() {
      if (this.candidateId && this.values.contractTemplate?.id) {
        const response = await actionableCanUseContractTemplate(this.candidateId, this.values.contractTemplate.id);
        this.contractFailureReasons = response.data.reasons;
        if (this.contractFailureReasons.length === 0) {
          await this.setTradeNames(this.values.contractTemplate?.id);
        }
      }
    },
    async openUpdateCandidate() {
      this.candidateToUpdate = new Candidate();
      const response = await showCandidate(this.candidateId);
      this.candidateToUpdate.mapResponse(response.data.data);
      this.candidateUpdateDialog = true;
    },
    updateCandidateRequest() {
      return updateCandidate(this.candidateToUpdate);
    },
    formatDate(date) {
      return dayjs(date).format('DD-MM-YYYY');
    },
    async checkContractDatesActionable() {
      if (this.values.contractTemplate?.contractTypeId) {
        const response = await actionableContractPossibleForPeriod(
          this.candidateId,
          this.values.contractTemplate?.contractTypeId,
          this.values.startDate,
          this.values.endDate ? this.values.endDate : null,
        );
        this.newContractStartDate = response.data.data.newContractStartDate;
        this.currentContractEndDate = response.data.data.currentContractEndDate;
        this.willTerminateCurrentContract = response.data.data.willTerminateCurrentContract;
      }
    },
    backgroundImageSizeRestriction(value) {
      const MB = 1024*1024;
      const fileSizeLimitInBytes = 20*MB;
      return !value || value.size < fileSizeLimitInBytes || this.$t('errors.fileTooLarge', { size: '20MB' });
    },
  },
};
</script>
