<template>
  <KPaginatedAutocomplete
    v-bind="$attrs"
    :paginator="autocomplete"
    v-on="$listeners"
  />
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
import { autocomplete } from '@/modules/contract/api/template';

export default {
  name: 'ContractTemplateAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    candidateIsZzp: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    autocomplete(page, perPage, search, sortBy, descending, params) {
      params = { contractTypeIds: [1] };

      if (this.candidateIsZzp) {
        params.contractTypeIds.push(2);
      }

      return autocomplete(page, perPage, search, sortBy, descending, params);
    },
  },
};
</script>
