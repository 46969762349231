import { get, post, put, destroy } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/getPaginated';

export const index = (candidateId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`candidate/${candidateId}/contract`, { page, perPage, search, sortBy, descending, params });

export const autocomplete = (candidateId, data) => getPaginated(`candidate/${candidateId}/contract/autocomplete`, data);

export const show = (contractId) => get(`contract/${contractId}`);

export const create = (candidateId, contract) => post(`candidate/${candidateId}/contract`, contract);

export const update = (contract) => put(`contract/${contract.id}`, contract);

export const deleteContract = (contractId) => destroy(`contract/${contractId}`);

export const requestSigning = (contractId) => post(`contract/${contractId}/request-signing`);

export const sendSigningReminder = (contractId) => post(`contract/${contractId}/send-signing-reminder`);

export const fetchPdf = (contractId) => get(`contract/${contractId}/document`, { responseType: 'blob' });

export const signToggle = (contractId) => post(`contract/${contractId}/sign`);

export const getProbationOptions = (candidateId, tradeNameId, startDate, endDate, contractId) => get(`candidate/${candidateId}/contract/probation-possibilities`,
  {
    params: {
      tradeNameId,
      startDate,
      endDate,
      contractId,
    },
  });
