<template>
  <div
    :class="grid"
    class="k-field-wrapper"
  >
    <VTextField
      v-model="numberValue"
      v-bind="fieldProps"
      :label="translatedLabel"
      :rules="computedRules"
      step=".01"
      suffix="%"
      type="number"
    />
  </div>
</template>

<script>
import FieldMixin from '@/components/crud/fields/FieldMixin.vue';

export default {
  name: 'KPercentageField',
  mixins: [FieldMixin],
  inheritAttrs: false,
  props: {
    value: {
      type: [Number, String],
    },
  },
  data() {
    return {
      numberValue: 0,
    };
  },
  watch: {
    numberValue(value) {
      this.$emit('input', this.floatDecimals(value));
    },
  },
  created() {
    this.numberValue = this.value;
  },
  methods: {
    floatDecimals(numberInput) {
      return Number.parseFloat(numberInput).toFixed(2);
    },
  },
};
</script>
