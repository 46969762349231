import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated as getPaginatedOld } from '@/application/api/implementations/app/wrapper.js';
import { getPaginated } from '@/application/api/getPaginated';

export const autocomplete = (candidateId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`candidate/${candidateId}/placement/autocomplete`, { page, perPage, search, sortBy, descending, params });

export const autocompleteForContract = (candidateId, contractTypeId, page, perPage, search, sortBy, descending, params) =>
  getPaginatedOld(`candidate/${candidateId}/contract/placement/autocomplete`, page, perPage, search, sortBy, descending, {
    ...params,
    contractTypeId,
  });

export const show = (placementId) => get(`placement/${placementId}`);

export const create = (candidateId, placement) => post(`candidate/${candidateId}/placement`, placement);

export const update = (placement) => put(`placement/${placement.id}`, placement);

export const remove = (placementId) => destroy(`placement/${placementId}`);

export const getPlacementSummaryInfo = (priceAgreementId, params) => get(`price-agreement/${priceAgreementId}/placement-info`, { params });

export const requestSigning = (candidateId, placementId, placementDocumentTypeId) => post(`candidate/${candidateId}/placement/${placementId}/type/${placementDocumentTypeId}/request-signing`);

export const sendSigningReminder = (candidateId, placementId, placementDocumentTypeId) => post(`candidate/${candidateId}/placement/${placementId}/type/${placementDocumentTypeId}/send-signing-reminder`);

export const fetchPdf = (candidateId, placementId, placementDocumentTypeId) => get(`candidate/${candidateId}/placement/${placementId}/type/${placementDocumentTypeId}/document`, { responseType: 'blob' });

export const fetchTravelCosts = (params) => get('candidate-location-travel-cost/calculate', { params });

export const placementDocumentTemplateAutocomplete = (data) => getPaginated('placement-document-template/autocomplete', data );
