import placement from '@/application/enums/placement';
import Model from '@/application/models/model.js';
import dayjs from '@/plugins/dayjs';
import mapFileForRequest from '@/application/util/mapFileForRequest';

export default class Placement extends Model {
  id = 0;
  contract = null;
  employer = null;
  contactPersonId = null;
  location = null;
  position = null;
  startDate = null;
  endDate = null;
  jobScale = null;
  periodic = null;
  grossHourlyWage = null;
  customerRate = null;
  passOnTravelCostToCustomer = true;
  minimumHours = 0;
  maximumHours = null;
  placementHourPeriodId = placement.timeUnits.WEEKS;
  vatChargingPercentage = null;
  showVatOptions = false;
  jobId = null;
  periodical = null;
  payScaleId = null;
  salaryTableId = null;
  priceAgreementId = 0;
  hasPriceAgreementException = false;
  priceAgreementExceptionReason = null;
  customerMargin = null;
  employerDocumentTemplate = null;
  candidateDocumentTemplate = null;
  usesFixedCommutingTravelCosts = false;
  travelCosts = [];
  tradeName = null;
  isManuallySignedEmployer = false;
  employerSignedDocument = null;
  isManuallySignedCandidate = false;
  candidateSignedDocument = null;
  isEditable = false;
  locationIsEditable = false;

  mapForRequest() {
    return {
      employerId: this.employer?.id,
      contactPersonId: this.contactPersonId || null,
      locationId: this.location?.id,
      contractId: this.contract?.id,
      positionId: this.position?.id,
      startDate: this.startDate,
      endDate: this.endDate,
      jobScale: this.jobScale,
      periodic: this.periodic,
      grossHourlyWage: Math.round(this.grossHourlyWage * 100) || null,
      customerRate: Math.round(this.customerRate * 100) || null,
      customerMargin: Math.round(this.customerMargin * 100) || null,
      passOnTravelCostToCustomer: this.passOnTravelCostToCustomer,
      minimumHours: dayjs.duration(this.minimumHours, 'hours').asMinutes(),
      maximumHours: this.maximumHours ? dayjs.duration(this.maximumHours, 'hours').asMinutes() : null,
      placementHourPeriodId: this.placementHourPeriodId,
      vatChargingPercentage: this.vatChargingPercentage,
      vatChargingOptionId: this.contract.vatChargingOptionId,
      jobId: this.jobId,
      periodical: this.periodical?.periodical,
      payScaleId: this.payScaleId,
      salaryTableId: this.salaryTableId,
      priceAgreementId: this.priceAgreementId,
      hasPriceAgreementException: !!this.hasPriceAgreementException,
      priceAgreementExceptionReason: this.priceAgreementExceptionReason,
      employerDocumentTemplateId: this.employerDocumentTemplate?.id,
      candidateDocumentTemplateId: this.candidateDocumentTemplate?.id,
      usesFixedCommutingTravelCosts: this.usesFixedCommutingTravelCosts,
      travelCosts: this.mapTravelCostsRequest(this.travelCosts),
      tradeNameId: this.tradeName?.id,
      isManuallySignedEmployer: this.isManuallySignedEmployer,
      employerSignedDocument: mapFileForRequest(this.employerSignedDocument),
      isManuallySignedCandidate: this.isManuallySignedCandidate,
      candidateSignedDocument: mapFileForRequest(this.candidateSignedDocument),
    };
  }

  mapTravelCostsRequest(travelCosts) {
    travelCosts.map(travelCost => {
      travelCost.total = Math.round(travelCost.total * 100);
      return travelCost;
    });

    return travelCosts;
  }

  mapTravelCostsResponse(travelCosts) {
    travelCosts.map(travelCost => {
      travelCost.total = travelCost.total / 100;
      return travelCost;
    });

    return travelCosts;
  }

  mapResponse(data) {
    data.grossHourlyWage = data.grossHourlyWage / 100;
    data.customerRate = data.customerRate / 100;
    data.customerMargin = data.customerMargin / 100;
    data.minimumHours = dayjs.duration(data.minimumHours, 'minutes').asHours();
    data.maximumHours = data.maximumHours !== null ? dayjs.duration(data.maximumHours, 'minutes').asHours() : null;
    data.showVatOptions = data.vatChargingPercentage !== null;
    data.locationIsEditable = !data.location;

    return super.mapResponse(data);
  }
}
