import { contract } from '@/application/enums/contract';
import Model from '@/application/models/model.js';
import mapFileForRequest from '@/application/util/mapFileForRequest';

class Contract extends Model {
  id = 0;
  contractTemplate;
  startDate = null;
  endDate = null;
  probationPeriodId = 0;
  vatChargingOptionId = null;
  hasDefaultMediation = true;
  mediation = 0;
  contractPrefundingTypeId = 1;
  hours = null;
  placementHourPeriodId = contract.timeUnits.WEEKS;
  tradeName = null;
  initialPlacement = null;
  isManuallySigned = false;
  candidateSignedDocument = null;
  wasManuallySignedOnOpen = false;
  hadInitialPlacementOnOpen = false;

  mapForRequest() {
    return {
      contractPrefundingTypeId: this.contractPrefundingTypeId,
      vatChargingOptionId: this.vatChargingOptionId,
      hasDefaultMediation: this.hasDefaultMediation,
      mediation: Math.round(this.mediation * 100),
      contractTemplateId: this.contractTemplate?.id,
      startDate: this.startDate,
      endDate: this.endDate || undefined,
      probationPeriodId: this.probationPeriodId,
      placementHourPeriodId: this.placementHourPeriodId,
      hours: this.hours * 60,
      tradeNameId: this.tradeName?.id,
      initialPlacementId: this.initialPlacement ? this.initialPlacement.id : null,
      isManuallySigned: this.isManuallySigned,
      candidateSignedDocument: mapFileForRequest(this.candidateSignedDocument),
    };
  }

  mapResponse(data) {
    return super.mapResponse({
      ...data,
      probationPeriodId: data.probationPeriodId === null ? 0 : data.probationPeriodId,
      mediation: data.mediation / 100,
      hours: data.hours / 60,
      initialPlacement: data.initialPlacement?.id ? data.initialPlacement : null,
      wasManuallySignedOnOpen: data.isManuallySigned,
      hadInitialPlacementOnOpen: !!data.initialPlacement,
    });
  }
}

export default Contract;
